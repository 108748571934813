.table{
  tfoot > tr > th:first-child,
  thead > tr > th:first-child,
  tr th:first-child,
  tr td:first-child{ padding-left: $card-spacer-x; }
  tfoot > tr > th:last-child,
  thead > tr > th:last-child,
  tr th:last-child,
  tr td:last-child{ padding-right: $card-spacer-x; }
}
.white-space-nowrap{
  width: 1px;
  white-space: nowrap;
}
th{ font-weight: $font-weight-semi-bold; }
td .dropdown-toggle {
  &:after, &:before {
    display: none;
  }
}
.table-dashboard{
  overflow: hidden;
  th{ border-bottom-width: 1px !important; }
}

.table-sm > :not(caption) > * > * {
  padding: 0.2rem !important;
  padding-left: 1rem !important;
}

.table-header{
  padding: 0px !important;
}

.mars-pb-table {
  /* progressbar in table */
  height: 10px !important;
  max-width: 50px !important;
}

/*-----------------------------------------------
|   Table OLD
-----------------------------------------------*/
// .table{
//   tfoot > tr > th:first-child,
//   thead > tr > th:first-child,
//   tr th:first-child,
//   tr td:first-child{ padding-left: $card-spacer-x; }
// }
// .white-space-nowrap{
//   width: 1px;
//   white-space: nowrap;
// }
// th{ font-weight: $font-weight-semi-bold; }
// td .dropdown-toggle:after{ display: none; }
// .table-dashboard{
//   overflow: hidden;
//   th{ border-bottom-width: 1px !important; }
// }

