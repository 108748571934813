/*-----------------------------------------------
|   Flex
-----------------------------------------------*/
.flex-center {
  align-items: center;
  justify-content: center;
}
.flex-between-center {
  align-items: center;
  justify-content: space-between;
}
.flex-end-center {
  justify-content: flex-end;
  align-items: center;
}
.flex-start-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}