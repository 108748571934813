// 
// 
// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

// -------------------------------------------------- //
// -------------- PageTitle.js styling -------------- //
// -------------------------------------------------- //
.page-title-wrapper {
  margin: 5px 0px 15px 0px;
}
.page-title {
  margin-top: -2px;
  padding-bottom: 0px;
  margin-left: 5px;
  width: 100%;
}

.page-title-main {
  width: auto;
  background-color: white;
  font-weight: 600;
  font-size: 20px;
  white-space: nowrap;
  padding-right: 10px;
  margin: 0;
}

.title-hr-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.page-subtitle {
  margin-top: -5px;
  margin-bottom: 0px;
  padding: 0;
  width: 100%;
}

.page-title-hr {
  width: 100%;
  border: 1.5px solid $gray-300;
  margin: 0;
  margin-top: 15px;
  padding: 0;
}
// -------------------------------------------------- //

.pt-fix{
  padding-top: 50px !important;
}

.w-15{
  width: 15% !important;
}

.w-10{
  width: 10% !important;
}

.mr-auto{
  margin-right: auto !important;
}

.mt--1{
  margin-top: -12px !important;
}

.w-60 {
  width: 60% !important;
}

.w-filter {
  width: 13% !important;
}

.fw-semi-bold {
  font-weight: 600 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.p-0 {
  padding: 0;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

:root {
  --mars-stage1: #add136;
  --mars-stage2: #50C1EF;
  --mars-stage3: #FFC32D;
  --mars-stage4: #00B050;
  --mars-stage5: #5F7D95;
  --mars-stage6: #6F3996;
  --mars-default: #d8e2ef;
  --mars-blue: #000096;
  --mars-red: #FF1414;
  --mars-yellow: #FFC828;
  --mars-pink: #F00A50;
  --mars-undef: #d8e2ef;
}

.mars-bg-stage1 {
  /* light green */
  background-color: var(--mars-stage1) !important;
}

.mars-bg-stage2 {
  /* light blue */
  background-color: var(--mars-stage2) !important;
}

.mars-bg-stage3 {
  /* orange */
  background-color: var(--mars-stage3) !important;
}

.mars-bg-stage4 {
  /* dark green */
  background-color: var(--mars-stage4) !important;
}

.mars-bg-stage5 {
  /* grey */
  background-color: var(--mars-stage5) !important;
}

.mars-bg-stage6 {
  /* purple */
  background-color: var(--mars-stage6) !important;
}

.mars-bg-blue {
  /* purple */
  background-color: var(--mars-blue) !important;
}

.mars-stage1 {
  background-color: var(--mars-stage1) !important;
  color: white !important;
  border-color: var(--mars-stage1) !important;
  border-width: thin !important;
  border-style: solid !important;
}

.mars-stage2 {
  background-color: var(--mars-stage2) !important;
  color: white !important;
  border-color: var(--mars-stage2) !important;
  border-width: thin !important;
  border-style: solid !important;
}

.mars-stage3 {
  background-color: var(--mars-stage3) !important;
  color: white !important;
  border-color: var(--mars-stage3) !important;
  border-width: thin !important;
  border-style: solid !important;
}

.mars-stage4 {
  background-color: var(--mars-stage4) !important;
  color: white !important;
  border-color: var(--mars-stage4) !important;
  border-width: thin !important;
  border-style: solid !important;
}

.mars-stage5 {
  background-color: var(--mars-stage5) !important;
  color: white !important;
  border-color: var(--mars-stage5) !important;
  border-width: thin !important;
  border-style: solid !important;
}

.mars-stage6 {
  background-color: var(--mars-stage6) !important;
  color: white !important;
  border-color: var(--mars-stage6) !important;
  border-width: thin !important;
  border-style: solid !important;
}

.mars-stage-total {
  border-color: var(--mars-stage5);
  border-width: thin;
  border-style: solid;
}

.mars-stage-span {
  display: inline-block;
  width: 75px;
  text-align: center
}

.mars-blue {
  color: var(--mars-blue);
}

.mars-red {
  color: var(--mars-red);
}

.mars-c1 {
  color: var(--mars-stage1);
}

.mars-c2 {
  color: var(--mars-stage2);
}

.mars-c3 {
  color: var(--mars-stage3);
}

.mars-c4 {
  color: var(--mars-stage4);
}

.mars-c5 {
  color: var(--mars-stage5);
}

.mars-c6 {
  color: var(--mars-stage6);
}

.mars-cd {
  /* default (light grey) */
  color: var(--mars-default);
}

.mars-button {
  color: white !important;
  background-color: var(--mars-blue) !important;
}
.mars-button-add {
  color: white !important;
}

.mars-button-remove {
  color: white !important;
  background-color: red !important;
}

.mars-button-inactive {
  color: white !important;
  background-color: #CCCCCC !important;
}

.mars-pb-table {
  /* progressbar in table */
  height: 10px !important;
  max-width: 50px !important;
}

.alert-text {
  display: inline-block;
  vertical-align: middle;
}

.truncate {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.justify {
  text-align: justify;
  text-justify: inter-word;
}

.input-group-text {
  width: 40% !important;
  margin-bottom: 0.25rem !important;
  font-size: 0.6944444444rem !important;
}

.input-group-sm{
  max-width: 350px !important;
}

.hoverhand{
  cursor: pointer;
}

.input-height{
  height: 28 px !important;
}

.form-control {
  font-size: 0.6944444444rem !important;
  margin-bottom: 0rem !important;
  padding: 0 0.25rem 0 0.25rem !important;
}

.form-height {
  height: 55% !important;
}

.form-select {
  font-size: 0.6944444444rem !important;
  margin-bottom: 0.25rem !important;
  padding: 0 0.25rem 0 0.25rem !important;
  background-position: right 0rem center !important;
}

.btn-sm-il {
  font-size: 0.6944444444rem !important;
  margin-bottom: 0.25rem !important;
  width: 85px;
}

.textarea {
  display: block;
  width: 100%;
  overflow: hidden;
  resize: both;
  min-height: 40px;
}

.textarea[contenteditable]:empty::before {
  content: "Placeholder still possible";
  color: gray;
}

.il-text {
  line-height: 1rem !important;
}

#more {display: none;}

.multiselect-dropdown {
  font-size: 0.6944444444rem !important;
  margin-bottom: 0.25rem !important;
  padding: 0.25rem 0.5rem 0 0.25rem !important;
  background-position: right 0rem center !important;
  border: 1px solid #d8e2ef !important;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
}

.multiselect-dropdown-list {
  height: 5rem !important;
}

.multiselect-dropdown-list div {
  padding: 0 !important;
}

.multiselect-dropdown-list-wrapper {
  padding: 0 !important;
}

.multiselect-dropdown-list label {
  font-size: 8px !important;
  margin-bottom: 0 !important;
}

/* Style the tab */
.tab {
  overflow: hidden;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: #FAFAFA;
  float: left;
  border-width: 1px;
  border-style: solid;
  border-color: #ddd;
  outline: none;
  cursor: pointer;
  padding: 5px 5px;
  margin: 2px;
  transition: 0.3s;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent01, .tabcontent02, .tabcontent03 {
  display: none;
}

.mce-content-body {
  font-size: 0.6944444444rem !important;
  font-family: "Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-weight: 300 !important;
  color: #5e6e82;
}

.dropzone .dz-message {
  padding: 1rem !important;
}

.dropzone {
  min-height: 50px !important;
}

.progress-white {
  background-color: #fff !important;
}

.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

// -------------------------------------------------- //
// ----------- PageinationTable.js styling ---------- //
// -------------------------------------------------- //
.table-cell {
  padding: 0px 2px !important;
  margin: 0 !important;
  font-family: $font-family-sans-serif !important;
  font-size: 0.7rem !important;
}

.comparison-table-titles {
  padding: 2px 2px !important;
  margin: 0 !important;
  font-family: $font-family-sans-serif !important;
  font-size: 0.7rem !important;
  color: $gray-600 !important;
}

.table-cell-body {
  color: $gray-600 !important;
  padding: 6px 2px !important;
  border-color: #EDF2F9 !important;
}

.table-sort-icon {
  color: #adb3bb !important;
}

.table-header {
  background-color: #EDF2F9 !important;
}

.pagination-item {
  color: $gray-600 !important;
  margin: auto !important;
  font-family: $font-family-sans-serif !important;
  font-size: 11px !important;
}

.sticky-table {
  position: sticky !important;
  left: 0 !important;
  background-color: white !important;
}

.sticky-header {
  position: sticky !important;
  left: 0 !important;
  background-color: #EDF2F9 !important;
}

.text-normal {
  font-family: $font-family-sans-serif !important;
  font-size: 0.7rem !important;
  color: gray-700;
}

.filter-row{
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
}

.fw-900{
  font-weight: 700 !important;
}

.avatar.rounded-circle img {
  //border-radius: 50% !important; 
  height: 12px !important;
}

input:focus { 
  outline: none !important;
  border-color: #719ECE;
  box-shadow: 0 0 10px #719ECE;
}

input:disabled {
  background-color: #fafafa !important;

}

.input-dollar {
  position: relative;
}

.input-dollar input {
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid;
  border-radius: 5%;
  border-color: #DDDDDD !important;
  width: 100%;
}

.input-dollar:after {
  position: absolute;
  top: 0;
  content:"($)";
  right: 5px;
}

.stage-dollar {
  position: relative;
}

.stage-dollar input {
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid;
  border-radius: 5%;
  border-color: #DDDDDD !important;
  width: 100%;
}

.stage-dollar:after {
  position: absolute;
  padding-top: 4px;
  color: #AAA;
  top: 0;
  content:"($)";
  right: 6px;
}

.stage-procent {
  position: relative;
}

.stage-procent input {
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid;
  border-radius: 5%;
  border-color: #DDDDDD !important;
  width: 100%;
}

.stage-procent:after {
  position: absolute;
  padding-top: 4px;
  color: #AAA;
  top: 0;
  content:"(%)";
  right: 6px;
}

.stage-compare {
  position: relative;
}

.stage-compare input {
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid;
  border-radius: 5%;
  border-color: #DDDDDD !important;
  width: 100%;
}

.stage-compare:after {
  position: absolute;
  padding-top: 4px;
  color: #AAA;
  top: 0;
  content:"(x:x)";
  right: 6px;
}

.stage-number {
  position: relative;
}

.stage-number input {
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid;
  border-radius: 5%;
  border-color: #DDDDDD !important;
  width: 100%;
}

.stage-number:after {
  position: absolute;
  padding-top: 4px;
  color: #AAA;
  top: 0;
  content:"(#)";
  right: 6px;
}

// .input-euro:focus-within::after {
//   padding-right: 20px !important;
// }

// .input-euro:hover::after {

//   padding-right: 20px !important;
// }

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.toggle-switch {
  position: relative;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  &-checkbox {
    display: none;
  }
  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 3px;
    margin: 0;
  }
  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.2s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 24px;
      padding: 0;
      line-height: 24px;
      font-size: 11px;
      color: white;
      //font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: "Edit";
      //text-transform: uppercase;
      padding-left: 10px;
      background-color: #000096;
      color: #fff;
    }
  }
  &-disabled {
    background-color: #ddd;
    cursor: not-allowed;
    &:before {
      background-color: #ddd;
      cursor: not-allowed;
    }
  }
  &-inner:after {
    content: "Read";
    //text-transform: uppercase;
    padding-right: 10px;
    background-color: #bbb;
    color: #fff;
    text-align: right;
  }
  &-switch {
    display: block;
    width: 8px;
    height: 8px;
    margin: 8px;
    margin-right: 10px;
    margin-left: 10px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 65%;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.2s ease-in 0s;
  }
  &-checkbox:checked + &-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      right: 0px;
    }
  }
}

.socials-button {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  box-shadow: 3px 3px 3px #ccc;
}

.bg-gray{
  background-color: #eee
}

.color-gray{
  color: #bbb;
}

.insta-color{
  color: #E1306C;
}

.fb-color{
  color: #4267B2;
}

.twitter-color{
  color: #1DA1F2;
}

.tiktok-color{
  color: #25F4EE;
}